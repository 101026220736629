import { useState } from "react";
import { ChoiceCards } from "../sections/ChoiceCards";
import { Expertises } from "../sections/Expertises";
import { HomeHero } from "../sections/HomeHero";
import { QuickLinks } from "../sections/QuickLinks";
import { StoriesCarousel } from "../sections/StoriesCarousel";
import { themeColor } from "../types";
import { ColorShiftTrigger } from "../wrappers/ColorShift";

export const HomeTemplate = ({ page }: any) => {
  const [carouselBg, setCarouselBg] = useState<themeColor>("light-green");
  const handleCarouselBg = (color: themeColor) => {
    if (document && document.getElementById("colorShift")) {
      const el = document.getElementById("colorShift");
      if (el) {
        el.style.backgroundColor = `var(--color-${color})`;
      }
    }
    setCarouselBg(color);
  };
  return (
    <>
      <ColorShiftTrigger color="light-yellow">
        <HomeHero {...page.hero} />
      </ColorShiftTrigger>
      <ColorShiftTrigger color="light-yellow">
        <ChoiceCards {...page.choiceCards} />
      </ColorShiftTrigger>
      <ColorShiftTrigger color="light-yellow">
        <Expertises {...page.expertises} background={true} />
      </ColorShiftTrigger>
      <ColorShiftTrigger color={carouselBg}>
        <StoriesCarousel
          {...page.storiesCarousel}
          handleCarouselBg={handleCarouselBg}
        />
      </ColorShiftTrigger>
      <ColorShiftTrigger color="light-green">
        <QuickLinks {...page.quickLinks} />
      </ColorShiftTrigger>
    </>
  );
};
