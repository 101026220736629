import Image from "next/image";
import { Button, ButtonProps } from "../components/Button";
import { Deco } from "../components/Deco";
import styles from "./ChoiceCards.module.css";

export type ChoiceCardProps = {
  title: string;
  text: string;
  figure: any;
  primaryButton: ButtonProps;
  secondaryButton: ButtonProps;
};

export type ChoiceCardsProps = {
  title: string;
  choices: ChoiceCardProps[];
};

export const ChoiceCards = ({ title, choices }: ChoiceCardsProps) => {
  return (
    <section className={styles.container}>
      <div className={styles.maxWidth}>
        <h2 className={styles.title}>
          <Deco deco="top-left-sparkle-magenta">{title}</Deco>
        </h2>
        <div className={styles.choiceCardsWrapper}>
          {choices &&
            choices.map((choice, i) => {
              return (
                <div key={i} className={styles.card}>
                  <h3 className={styles.cardTitle}>{choice.title}</h3>
                  <p className={styles.cardText}>{choice.text}</p>
                  <div className={styles.buttons}>
                    <Button {...choice.primaryButton} />
                    <Button {...choice.secondaryButton} variation="secondary" />
                  </div>
                  <Image
                    src={choice.figure}
                    className={styles.figure}
                    alt=""
                    width={240}
                    height={313}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
