import Link from "next/link";
import React from "react";
import { Icon } from "../components/Icon";
import { SectionTitle } from "../components/SectionTitle";
import { MaxWidth } from "../layouts/MaxWidth";
import { Spacing } from "../layouts/Spacing";
import styles from "./QuickLinks.module.css";
import { RichText } from "./RichText";
import cx from "classnames";
import { createInternalHref } from "../helpers/createInternalHref";
import { LinkWrapper } from "../components/linkWrapper";

export type QuickLinkProps = {
  label: string;
  url: string;
};

export const QuickLink = ({ label, url }: QuickLinkProps) => {
  if (!url) url = "#";
  return (
    <LinkWrapper url={url} className={styles.quicklink}>
      <span className={styles.label}>{label}</span>
      <Icon icon="arrow" className={styles.icon} />
    </LinkWrapper>
  );
};

export type QuickLinksProps = {
  title: string;
  text?: string;
  links: QuickLinkProps[];
};

export const QuickLinks = ({ title, text, links }: QuickLinksProps) => {
  // if (!links) return null;
  const hasText = text;
  const align = hasText ? "left" : "center";
  const deco = hasText ? "top-left-sparkle-green" : "arrow-down";
  return (
    <Spacing>
      <MaxWidth size="sm">
        <SectionTitle deco={deco} align={align}>
          {title}
        </SectionTitle>
        {text && <RichText text={text} />}
        <div className={cx(styles.links, { [styles.hasText]: hasText })}>
          {links &&
            links.map((item) => (
              <QuickLink key={item.url + item.label} {...item} />
            ))}
        </div>
      </MaxWidth>
    </Spacing>
  );
};
