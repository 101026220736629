import React from "react";
import { MaxWidth } from "../layouts/MaxWidth";
import { Spacing } from "../layouts/Spacing";
import styles from "./Expertises.module.css";
import cx from "classnames";
import Link from "next/link";
import { CtaLine } from "../components/CtaLine";
import { Figure } from "../components/Figure";
import { Flex } from "../layouts/Flex";
import { Deco } from "../components/Deco";
import { Underline } from "../components/StrongLink";
import { LinkWrapper } from "../components/linkWrapper";
import { Dump } from "../components/Dump";

export type ExpertiseCardProps = {
  title: string;
  text: string;
  image: string;
  url: string;
};

export const ExpertiseCard = ({
  title,
  text,
  image,
  url,
}: ExpertiseCardProps) => {
  return (
    <LinkWrapper key={title} url={url} className={styles.card}>
      <Figure
        image={image}
        width={96}
        height={96}
        className={styles.cardImage}
        styled={false}
      />
      <div className={styles.cardText}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <p className={styles.cardText}>{text}</p>
      </div>
    </LinkWrapper>
  );
};

export type ExpertisesProps = {
  background: boolean;
  title: string;
  text: string;
  items: {
    title: string;
    text: string;
    image: string;
    url: string;
  }[];
  ctaTitle: string;
  ctaLabel: string;
  ctaUrl: string;
};

export const Expertises = ({
  background,
  title,
  text,
  items,
  ctaTitle,
  ctaLabel,
  ctaUrl,
}: ExpertisesProps) => {
  const hasCta = ctaTitle && ctaLabel && ctaUrl;
  return (
    <div
      className={cx(
        styles.container,
        { [styles.bg]: background },
        { [styles.noBg]: !background }
      )}
    >
      <MaxWidth>
        <div className={cx(styles.intro, { [styles.introCenter]: background })}>
          <h2 className={styles.title}>
            <Deco
              deco={
                background
                  ? "top-center-scribble-yellow"
                  : "top-left-sparkle-green"
              }
            >
              {title}
            </Deco>
          </h2>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.cards}>
          <Flex items={3}>
            {items &&
              items.map((item, index) => (
                <ExpertiseCard key={index} {...item} />
              ))}
            {background && hasCta && (
              <LinkWrapper url={ctaUrl} className={styles.ctaCard}>
                <h3 className={styles.ctaTitle}>{ctaTitle}</h3>
                <p className={styles.ctaText}>
                  {ctaLabel}
                  <Underline className={styles.underline} />
                </p>
              </LinkWrapper>
            )}
          </Flex>
        </div>
        {!background && hasCta && (
          <CtaLine
            title={ctaTitle}
            buttonText={ctaLabel}
            url={ctaUrl}
            className={styles.ctaLine}
          />
        )}
      </MaxWidth>
    </div>
  );
};
