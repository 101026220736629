import { linkUrlGroq } from "../sanity/schemas/link";

export const quickLinksSchema = {
  type: "object",
  name: "quickLinks",
  title: "Quick Links",
  options: {
    collapsible: true,
    collapsed: false,
  },
  fields: [
    {
      name: "title",
      type: "string",
    },
    {
      name: "text",
      title: "Intro text",
      type: "text",
      rows: 3,
    },
    {
      name: "links",
      type: "array",
      of: [
        {
          type: "object",
          name: "link",
          title: "Quicklink",
          fields: [
            {
              name: "label",
              title: "label",
              type: "string",
            },
            {
              name: "url",
              title: "Link",
              type: "linkUrl",
            },
          ],
        },
      ],
    },
  ],
};

export const quickLinkSchema = {
  type: "object",
  name: "quickLink",
  title: "Quick Link",
  fields: [
    {
      name: "label",
      title: "label",
      type: "string",
    },
    {
      name: "url",
      title: "Link",
      type: "linkUrl",
    },
  ],
};

export const quickLinksGroq = `
  title,
  text,
  "links": links{
    label,
    "url": ${linkUrlGroq}
  }[]
`;
