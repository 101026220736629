export const expertisesSchema = {
  type: "object",
  name: "expertises",
  options: {
    collapsible: true,
    collapsed: false,
  },
  fields: [
    {
      name: "title",
      title: "Titel",
      type: "string",
    },
    {
      name: "text",
      title: "Intro tekst",
      type: "text",
      rows: 3,
    },
    {
      name: "expertises",
      title: "Expertises",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "expertise" }],
        },
      ],
    },
    {
      name: "ctaTitle",
      title: "CTA titel",
      type: "string",
    },
    {
      name: "ctaLabel",
      title: "CTA knoplabel",
      type: "string",
    },
    {
      name: "ctaUrl",
      title: "CTA url",
      type: "link",
    },
  ],
};

export const expertisesGroq = `
  ...,
  "items": expertises[]->{
    title,
    "text": tagLine,
    "image": icon,
    "url": "/vakgebieden/"+slug.current,
  },
  "ctaUrl": ctaUrl->slug.current
`;
