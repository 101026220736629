import { linkUrlGroq } from "../sanity/schemas/link";
import { validate } from "../sanity/utils/validate";

export const homeHeroSchema = {
  type: "object",
  name: "hero",
  title: "Home hero",
  options: {
    collapsible: true,
    collapsed: false,
  },
  fields: [
    {
      name: "title",
      title: "Title",
      type: "string",
      validation: validate({ required: true }),
    },
    {
      name: "text",
      title: "Intro tekst",
      type: "text",
      rows: 4,
    },
  ],
};

export const homeHeroGroq = `
  ...,
  "image": figure,
  "button": button{
    "url": ${linkUrlGroq},
    label
  }
`;
