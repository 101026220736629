import { Deco } from "../components/Deco";
import { MaxWidth } from "../layouts/MaxWidth";
import { Spacing } from "../layouts/Spacing";
import styles from "./HomeHero.module.css";

export type HomeHeroProps = {
  title: string;
  text?: string;
};

export const HomeHero = ({ title, text }: HomeHeroProps) => {
  if (!title) return null;
  return (
    <header className={styles.header}>
      <Spacing size="sm">
        <MaxWidth size="sm">
          <h1 className={styles.title}>
            <Deco deco="dot">{title}</Deco>
          </h1>
          {text && <p className={styles.text}>{text}</p>}
        </MaxWidth>
      </Spacing>
    </header>
  );
};
