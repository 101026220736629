import { linkUrlGroq } from "../sanity/schemas/link";

export const stepsSchema = {
  title: "Steps",
  name: "steps",
  type: "object",
  options: {
    collapsible: true,
    collapsed: false,
  },
  fields: [
    {
      name: "title",
      title: "Title",
      type: "string",
    },
    {
      name: "text",
      title: "Text",
      type: "string",
    },
    {
      name: "image",
      title: "afbeelding",
      type: "image",
    },
    {
      name: "steps",
      type: "array",
      of: [
        {
          type: "object",
          fields: [
            {
              name: "title",
              title: "Title",
              type: "string",
            },
            {
              name: "text",
              title: "Text",
              type: "text",
              rows: 3,
            },
            {
              name: "button",
              title: "Button",
              type: "object",
              fields: [
                {
                  name: "label",
                  type: "string",
                },
                {
                  name: "url",
                  type: "url",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "cta",
      title: "CTA Line",
      type: "ctaLine",
    },
  ],
};

export const stepsGroq = `
  ...,
  "cta": cta{
    ...,
    "url": ${linkUrlGroq}
  }
`;
