import React from "react";
import { RichTextContainer } from "../sections/RichText";
import { Spacing } from "../layouts/Spacing";
import { PageTitle } from "../sections/PageTitle";

export const PageTemplate = ({ page }: any) => {
  return (
    <>
      <PageTitle {...page.pageTitle} />
      <RichTextContainer text={page.body} />
    </>
  );
};
