import { buttonLinkUrlSchema } from "../components/Button.sanity";
import { linkUrlGroq } from "../sanity/schemas/link";
import { validate } from "../sanity/utils/validate";

export const choiceCardsSchema = {
  type: "object",
  name: "choiceCards",
  title: "Keuze cards",
  options: {
    collapsible: true,
    collapsed: false,
  },
  fields: [
    {
      name: "title",
      title: "Title",
      type: "string",
      validation: validate({ required: true }),
    },
    {
      name: "choices",
      title: "Keuze cards",
      type: "array",
      of: [
        {
          type: "object",
          fields: [
            {
              name: "title",
              type: "string",
              title: "Titel",
            },
            {
              name: "text",
              type: "string",
              title: "Tekst",
            },
            {
              ...buttonLinkUrlSchema,
              title: "Primaire knop",
              name: "primaryButton",
            },
            {
              ...buttonLinkUrlSchema,
              title: "Secundaire knop",
              name: "secondaryButton",
            },
            {
              name: "figure",
              type: "figure",
            },
          ],
          preview: {
            select: {
              title: "title",
              subtitle: "text",
              media: "figure",
            },
          },
        },
      ],
    },
  ],
};

export const choiceCardsGroq = `
  ...,
  "choices": choices[]{
    ...,
    "figure": figure.asset->url,
    "thing": "thong",
    "primaryButton": primaryButton{
      ...,
      "href":  ${linkUrlGroq},
      "url": ${linkUrlGroq}
    },
    "secondaryButton": secondaryButton{
      ...,
      "href":  ${linkUrlGroq},
      "url": ${linkUrlGroq}

    }
  }
`;
