import groq from "groq";
import type { GetStaticPaths, GetStaticProps } from "next";
import { useRouter } from "next/router";
import { usePreviewSubscription } from "../helpers/sanity/sanity";
import { getClient } from "../helpers/sanity/server";
import { SchemaType } from "../sanity/types";
import { choiceCardsGroq } from "../sections/ChoiceCards.sanity";
import { expertisesGroq } from "../sections/Expertises.sanity";
import { FooterProps, footerQuery } from "../sections/Footer";
import { homeHeroGroq } from "../sections/HomeHero.sanity";
import { navQuery } from "../sections/Nav";
import { quickLinksGroq } from "../sections/QuickLinks.sanity";
import { richTextGroq } from "../sections/RichText.sanity";
import { stepsGroq } from "../sections/Steps.sanity";
import { storiesCarouselGroq } from "../sections/StoriesCarousel.sanity";
import { HomeTemplate } from "../templates/HomeTemplate";
import { PageTemplate } from "../templates/PageTemplate";
import { NavType } from "../types";
import { Page } from "../wrappers/Page";

const schemaType: SchemaType = "page";
const homepageSchemaType: SchemaType = "landing.homepage";

/**
 * Sanity Query
 */

const pageQuery = groq`
  {
    "page": *[_type == "${schemaType}" && slug.current == $slug && slug.current != 'cookies'][0] {
      "title": contentTitle, 
      "slug": slug.current,
      "parent": parent->{ "slug": slug.current }.slug,
      "pageTitle": {
        eyebrow,
        "title": contentTitle,
      },
      "body": body[]{
        ${richTextGroq}
      }
    },
    ${footerQuery},
    ${navQuery}
  }
`;

interface PageQueryType {
  page: {
    title?: string;
    slug?: string;
    parent?: string;
  };
  nav: NavType;
  footer: FooterProps | {};
}

const homePageQuery = groq`
{
  "page": *[_type == "${homepageSchemaType}"]{
    _id,
    title, 
    "parent": null,
    "slug": "homepage",
    "hero": hero{
      ${homeHeroGroq}
    },
    "choiceCards": choiceCards{
      ${choiceCardsGroq}
    },
    "expertises": expertises{
      ${expertisesGroq}
    },
    "steps": steps {
      ${stepsGroq}
    },
    "storiesCarousel": storiesCarousel{
      ${storiesCarouselGroq}
    },
    "quickLinks": quickLinks {
      ${quickLinksGroq}
    }
  }[0],
  ${footerQuery},
  ${navQuery},
} 
`;

interface HomePageQueryType {
  page: {
    _id?: string;
    title?: string;
    slug?: string;
    parent?: string;
  };
  nav: NavType;
  footer: FooterProps | {};
}

/**
 * Page
 */

interface Props {
  data: {
    post: PageQueryType | HomePageQueryType;
  };
  preview: boolean;
}

const SlugPage = ({
  data = { post: { page: {}, nav: [], footer: {} } },
  preview,
}: Props) => {
  let post;

  const router = useRouter();
  let isHomepage = router.asPath === "/";
  if (router.asPath.substring(0, 2) === "/?") {
    isHomepage = true;
  }
  post = usePreviewSubscription(isHomepage ? homePageQuery : pageQuery, {
    params: isHomepage
      ? {}
      : {
          slug: data.post?.page?.slug,
        },
    initialData: data.post,
    enabled: preview,
  }).data;

  if (!post?.page) {
    return <Page notFound={true} nav={post.nav} footer={post.footer} />;
  }

  const { title, slug } = post.page;

  const homePage = (
    <Page
      title={title}
      notFound={!slug}
      nav={post.nav}
      footer={post.footer}
      navColor="light-yellow"
      preview={preview}
    >
      <HomeTemplate page={post.page} />
    </Page>
  );

  const contentPage = (
    <Page
      title={title}
      notFound={!slug}
      nav={post.nav}
      footer={post.footer}
      navColor="white"
      preview={preview}
    >
      <PageTemplate page={post.page} />
    </Page>
  );

  return isHomepage ? homePage : contentPage;
};

export default SlugPage;

/**
 * Static props
 */

export const getStaticProps: GetStaticProps = async ({
  params,
  preview = false,
}) => {
  const slugs = params?.slug;

  let post: PageQueryType | HomePageQueryType;

  if (!slugs || !slugs.length) {
    // homepage
    post = await getClient(preview).fetch(homePageQuery);
  } else {
    // slug pages
    post = await getClient(preview).fetch(pageQuery, {
      slug: slugs[slugs.length - 1],
    });
  }

  if (!post?.page) {
    return {
      props: {
        notFound: true,
        data: { post: { page: {}, nav: post.nav, footer: post.footer } },
      },
    };
  }

  // prevent nested page to at root as well because of matching slug
  // /foo/faa should return 404 at /faa or at /foo/foo/faa
  // /other-parent/faa should return 404 as well
  if (
    slugs &&
    slugs?.length !== 2 &&
    post.page.parent &&
    slugs[0] !== post.page.parent
  ) {
    return { props: { notFound: true, data: { post: {} } } };
  }

  return {
    props: {
      preview,
      data: { post },
    },
    revalidate: 60,
  };
};

/**
 * Static paths
 */

export const getStaticPaths: GetStaticPaths = async () => {
  const slugs: string[] = await getClient(false).fetch(
    `*[_type == "${schemaType}"] { 
      _type, 
      "slug": slug.current, 
      parent-> { 
        "slug": slug.current 
      } 
    }{
      "path": coalesce(@.parent.slug + "/" + @.slug, @.slug)
    }.path`
  );

  slugs.push("/"); // homepage

  return {
    paths: slugs.map((slug) => ({ params: { slug: slug.split("/") } })),
    fallback: true,
  };
};
